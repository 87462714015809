import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AccountService } from '../services/account.service';
import { AlertService } from 'src/app/shared/alert/services/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private alertService: AlertService, private accountService: AccountService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            if (event.body.message) {
              this.alertService.add(event.body.message, {
                duration: 3000,
                type: event.body.status
              });
            }
          }
        },
        response => {
          this.alertService.add(response.error && response.error.message ? response.error.message : response.message, {
            duration: 3000,
            type: 'error'
          });
        }
      )
    );
  }
}
