<my-loading [error]="error" [loading]="loading" (reload)="getData()"></my-loading>
<div class="po-chart-container" *ngIf="dashboard">
  <div class="po-chart">
    <ngx-charts-bar-vertical
      [results]="dashboard.requestedParts"
      [xAxisLabel]="'Piezas'"
      [yAxisLabel]="'Cantidad saliente'"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [yScaleMin]="0"
      [yScaleMax]="0"
      [legend]="true"
      [legendTitle]="'Piezas con más salida hoy'"
    >
    </ngx-charts-bar-vertical>
  </div>
</div>
<div class="ui stackable padded grid" *ngIf="dashboard && session.accessLevel >= 9">
  <div class="three columns row">
    <div class="column">
      <h3 class="ui centered header">Persona que retira más productos</h3>
      <ngx-charts-pie-chart
        [results]="dashboard.requestedPersons"
        [legend]="true"
        [legendTitle]="false"
        [legendPosition]="'below'"
      >
      </ngx-charts-pie-chart>
    </div>
    <div class="column">
      <h3 class="ui centered header">Taller que retira más productos</h3>
      <ngx-charts-pie-chart
        [results]="dashboard.requestedWorkshops"
        [legend]="true"
        [legendTitle]="false"
        [legendPosition]="'below'"
      >
      </ngx-charts-pie-chart>
    </div>
    <div class="column">
      <h3 class="ui centered header">Horario frecuente de retiro</h3>
      <ngx-charts-pie-chart
        [results]="dashboard.requestedHours"
        [legend]="true"
        [legendTitle]="false"
        [legendPosition]="'below'"
      >
      </ngx-charts-pie-chart>
    </div>
  </div>
</div>
<div class="po-chart-container-middle" *ngIf="dashboard && session.accessLevel >= 9">
  <div class="ui centered header">Almacenes con más movimientos</div>
  <div class="po-chart">
    <ngx-charts-pie-grid
      [results]="dashboard.warehouseMovements"
    >
    </ngx-charts-pie-grid>
  </div>
</div>
<div class="ui stackable padded grid" *ngIf="dashboard">
  <div class="row">
    <div class="sixteen wide column">
      <h2>Productos con stock faltante</h2>
      <table class="ui selectable table">
        <thead>
          <tr>
            <th>Producto</th>
            <th>Stock actual</th>
            <th>Stock faltante</th>
            <th>Mínimo configurado</th>
            <th>Máximo configurado</th>
          </tr>
        </thead>
        <tbody>
          <tr [routerLink]="['/parts', 'view', part.id]" *ngFor="let part of dashboard.parts">
            <td>{{ part.name }}</td>
            <td>{{ part.quantity }}</td>
            <td>{{ part.minQuantity - part.quantity }}</td>
            <td>{{ part.minQuantity }}</td>
            <td>{{ part.maxQuantity }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

