<div  #dimmer [className]="'ui dimmer modals visible active'
+ (modalRef.options.scrolling ? ' scrolling' : '')
+ (animatingDimmer ? ' transition animating fade in' : (animatingOutDimmer ? ' transition animating fade out' : ''))">
  <div [className]="'po-modal-container ' + (
    animatingModal ? ' active visible transition animating in ' + (modalRef.options.basic ? 'zoom ' : 'fly down ') :
    (animatingOutModal
      ? ' active visible transition animating out ' + (modalRef.options.basic ? 'zoom' : 'fly down ')
      : (animatingModal === false ? ' active visible ' : '')
    )
  )">
    <div [className]="'ui modal active visible my-modal ' + (modalRef.options.basic ? ' basic ' : '')
    + (modalRef.options.size ? modalRef.options.size : ' tiny ')">
      <i class="icon close" aria-label="Cerrar" title="Cerrar ventana" (click)="cancel()" *ngIf="modalRef.options.closable"></i>
      <div class="header">
        <i [className]="'icon ' + modalRef.options.icon" *ngIf="modalRef.options.icon"></i>
        {{ modalRef.options.title }}
      </div>
      <div class="content" [class.unpadded]="modalRef.options.unpadded"
      [class.image]="modalRef.options.image" [style.min-height.px]="modalRef.options.minHeight || ''" #modalContent>
        <img [src]="modalRef.options.image" [className]="'ui image ' + (modalRef.options.imageSize ? modalRef.options.imageSize : '')"
        *ngIf="modalRef.options.image">
        <div class="description">
          <div #modalContainer></div>
        </div>
      </div>
      <div class="actions">
    <div class="actions">
      <ng-container *ngIf="modalRef.options.customButtons && modalRef.options.customButtons.length > 0">
        <button type="button" [className]="'ui button '
        + (button.float ? button.float + ' floated ' : '')
        + (componentRef.instance.loading
          ? ' disabled ' : ''
        )
        + (button.icon && !button.text ? ' icon ' : '')
        + button.type" (click)="button.callback(componentRef.instance)" [title]="button.text"
        *ngFor="let button of modalRef.options.customButtons">
          <i [className]="'icon ' + button.icon"></i>
          <span class="button-label">{{ button.text }}</span>
          <span class="button-label-alternate" *ngIf="button.alternatedText">{{ button.alternatedText }}</span>
        </button>
      </ng-container>

      <button type="button" class="ui cancel button" [class.basic]="modalRef.options.basic" [class.inverted]="modalRef.options.basic"
      (click)="cancel()" *ngIf="modalRef.options.closable && modalRef.options.defaultButtons.indexOf('cancel') !== -1">Cancelar</button>

      <button type="button" class="ui preview labeled icon button" (click)="preview()"
      title="Vista previa" [class.basic]="modalRef.options.basic" [class.inverted]="modalRef.options.basic"
      *ngIf="modalRef.options.closable && modalRef.options.defaultButtons.indexOf('preview') !== -1">
        <i class="zoom in icon"></i> <span class="button-label">Vista previa</span>
      </button>

      <button type="button" class="ui primary labeled icon button" (click)="success()"
      title="Aceptar" [class.basic]="modalRef.options.basic" [class.inverted]="modalRef.options.basic"
      *ngIf="modalRef.options.defaultButtons.indexOf('success') !== -1">
        <i class="checkmark icon"></i>
        Aceptar
      </button>

      <button type="button" class="ui secondary right labeled icon button" (click)="next()" title="Siguiente"
      [class.disabled]="componentRef.instance.loading || !componentRef.instance.currentStepIsValid()"
      [class.basic]="modalRef.options.basic" [class.inverted]="modalRef.options.basic"
      *ngIf="modalRef.options.defaultButtons.indexOf('stepper') !== -1 && !componentRef.instance.stepsComplete()">
        <span class="button-label">Siguiente</span>
        <i class="right arrow icon"></i>
      </button>

      <button type="button" class="ui primary labeled icon button" (click)="success()"
      [class.basic]="modalRef.options.basic" [class.inverted]="modalRef.options.basic"
      *ngIf="modalRef.options.defaultButtons.indexOf('send') !== -1" title="Enviar">
        <i class="checkmark icon"></i>
        Enviar
      </button>

      <button type="button" class="ui primary labeled icon button" (click)="finish()" title="Finalizar"
      [class.basic]="modalRef.options.basic" [class.inverted]="modalRef.options.basic"
      [class.disabled]="componentRef.instance.loading || !componentRef.instance.currentStepIsValid()"
      *ngIf="modalRef.options.defaultButtons.indexOf('stepper') !== -1 && componentRef.instance.stepsComplete()">
        <i class="checkmark icon"></i>
        Finalizar
      </button>

      <button type="button" class="ui primary labeled icon button" (click)="print()"
      title="Imprimir" [class.basic]="modalRef.options.basic" [class.inverted]="modalRef.options.basic"
      *ngIf="modalRef.options.defaultButtons.indexOf('print') !== -1">
        <i class="print icon"></i>
        Imprimir
      </button>

      <button type="button" class="ui button" (click)="success()"
      title="Listo" [class.basic]="modalRef.options.basic" [class.inverted]="modalRef.options.basic"
      *ngIf="modalRef.options.defaultButtons.indexOf('done') !== -1">
        Listo
      </button>
    </div>
  </div>
</div>
