<div [className]="'ui large message transition animating in fly up visible sha-alert ' + alert.type" *ngIf="alert">
  <i class="icon checkmark" *ngIf="alert.type == 'success'"></i>
  <i class="icon ban" *ngIf="alert.type == 'error'"></i>
  <i class="icon attention" *ngIf="alert.type == 'warning'"></i>
  {{ alert.text }}
  <button class="ui right floated compact mini button" (click)="close(alert.index)"
  *ngIf="alert.action != 'loading' && alert.action != 'none'">
    {{ alert.action ? alert.action : 'Ok!' }}
  </button>
  <i class="icon spinner loading" *ngIf="alert.action == 'loading'"></i>
</div>
