import { User } from 'src/app/core/models/user';
import { PipeTransform, Pipe } from '@angular/core';
import { AccountService } from 'src/app/core/services/account.service';
import { Session } from 'src/app/core/models/session';

@Pipe({ name: 'user' })
export class UserPipe implements PipeTransform {
  private session: Session;
  constructor(private accountService: AccountService) {
    this.session = this.accountService.getActiveSession();
  }

  transform(user: User, noUserText: string): string {
    if (user) {
      return this.session.name === user.name ? 'Usted' : user.name;
    }

    return noUserText || 'Usuario no disponible';
  }
}
