import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { ApiResponse } from '../models/response';
import { Session } from '../models/session';
import { User } from '../models/user';

export class Credentials {
  email: string;
  password: string;
  remember?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private sessionSubject: BehaviorSubject<Session> = new BehaviorSubject(this.extractCurrentSession());
  constructor(private http: HttpClient) {}

  public login(credentials: Credentials): Observable<ApiResponse> {
    return this.http.post<ApiResponse>('api/login', credentials);
  }

  public logout(complete?: () => void): Subscription {
    return this.http.get<ApiResponse>('api/logout').subscribe((response: ApiResponse) => {
      if (response.status === 'success') {
        this.removeCurrentSession();
      }
    }, () => {}, complete);
  }

  public view(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>('api/admin/account');
  }
  public modify(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>('api/admin/account/edit', user);
  }

  public removeUser(user: User): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>('api/admin/account/remove/' + user.id);
  }

  public setInactive(): void {
    const session: Session = this.sessionSubject.getValue();
    if (session) {
      session.inactive = true;
      this.storeCurrentSession(session);
    }
  }

  public getActiveSession(): Session {
    return this.sessionSubject.getValue();
  }

  public sessionExist(): boolean {
    return this.sessionSubject.getValue() ? true : false;
  }

  public observeActiveSession(): Observable<Session> {
    return this.sessionSubject.asObservable();
  }

  public extractCurrentSession(): Session {
    const storedSession = localStorage.getItem('currentSession');
    if (!storedSession || storedSession === 'null' || storedSession === 'undefined') {
      return null;
    }
    const session: Session = JSON.parse(storedSession);
    return session;
  }

  public storeCurrentSession(session: Session): void {
    localStorage.setItem('currentSession', JSON.stringify(session));
    this.sessionSubject.next(session);
  }

  public removeCurrentSession(): void {
    localStorage.removeItem('currentSession');
    this.sessionSubject.next(null);
  }
}
