import { ApiResponse } from './../models/response';
import { Branch } from './../models/branch';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Result } from '../models/result';
import { ConfigureHttpParams } from '../lib/functions';

@Injectable({
  providedIn: 'root'
})
export class BranchsService {
  private subject: BehaviorSubject<Branch> = new BehaviorSubject(this.extractCurrentBranch());
  constructor(private http: HttpClient) {}

  public index(result?: Result): Observable<ApiResponse> {
    const params = ConfigureHttpParams(result);
    return this.http.get<ApiResponse>('api/admin/branchs', { params });
  }

  public add(branch: Branch): Observable<ApiResponse> {
    return this.http.post<ApiResponse>('api/admin/branchs/add', branch);
  }

  public modify(branchId: string, branch: Branch): Observable<ApiResponse> {
    return this.http.post<ApiResponse>('api/admin/branchs/edit/' + branchId, branch);
  }

  public remove(branchId: string): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>('api/admin/branchs/remove/' + branchId);
  }

  public observeBranch(): Observable<Branch> {
    return this.subject.asObservable();
  }

  public setBranch(branch: Branch): void {
    this.subject.next(branch);
  }

  public getActiveBranch(): Branch {
    return this.subject.getValue();
  }

  public extractCurrentBranch(): Branch {
    const storedBranch = localStorage.getItem('currentBranch');
    if (!storedBranch || storedBranch === 'null' || storedBranch === 'undefined') {
      return null;
    }
    const branch: Branch = JSON.parse(storedBranch);
    return branch;
  }

  public storeCurrentBranch(branch: Branch): void {
    localStorage.setItem('currentBranch', JSON.stringify(branch));
    this.subject.next(branch);
  }

  public removeCurrentBranch(): void {
    localStorage.removeItem('currentBranch');
    this.subject.next(null);
  }
}
