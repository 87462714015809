import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, OnInit, OnDestroy, Input, AfterViewInit, ElementRef } from '@angular/core';
import { ModalRef } from '../../services/modal.service';

export const MODAL_CANCEL_RESULT = 'cancel';
export const MODAL_PREVIEW_RESULT = 'preview';
export const MODAL_SUCCESS_RESULT = 'success';
export const MODAL_NEXT_RESULT = 'next';
export const MODAL_PRINT_RESULT = 'print';

@Component({
  selector: 'my-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() modalRef: ModalRef;
  public componentRef: any;
  public animatingDimmer = true;
  public animatingModal = true;
  public animatingOutDimmer: boolean;
  public animatingOutModal: boolean;
  public scrollAnimator: any;
  @ViewChild('modalContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
  @ViewChild('modalContent', { static: false }) content: ElementRef;

  constructor(private resolver: ComponentFactoryResolver) {}

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.animatingDimmer = false;
      setTimeout(() => {
        this.animatingModal = false;
      }, 500);
    }, 500);
  }

  public ngOnInit(): void {
    this.entry.clear();
    this.modalRef.modal = this;
    const factory = this.resolver.resolveComponentFactory(this.modalRef.component);
    this.componentRef = this.entry.createComponent(factory);
    this.componentRef.instance.modalRef = this.modalRef;
    if (this.componentRef.instance.modalOptions) {
      this.modalRef.options = {...this.modalRef.options, ...this.componentRef.instance.modalOptions};
    }
  }

  public ngOnDestroy(): void {
    this.destroyModalComponent();
  }

  public scrollToBottom(): void {
    clearInterval(this.scrollAnimator);
    setTimeout(() => {
      this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
    });
  }

  public close(callback: () => void): void {
    this.animatingOutModal = true;
    this.animatingModal = null;
    setTimeout(() => {
      this.animatingOutModal = false;
      this.animatingOutDimmer = true;
      setTimeout(() => {
        callback();
      }, 500);
    }, 500);
  }

  public cancel(): void {
    if ( !this.modalRef.options.closable || this.componentRef.instance.loading) {
      return null;
    }
    this.componentRef.instance.onClose(MODAL_CANCEL_RESULT);
  }

  public preview(): void {
    if (this.componentRef.instance.loading) {
      return null;
    }
    this.componentRef.instance.onClose(MODAL_PREVIEW_RESULT);
  }

  public success(): void {
    if (this.componentRef.instance.loading) {
      return null;
    }
    this.componentRef.instance.onClose(MODAL_SUCCESS_RESULT);
  }

  public next(): void {
    if (this.componentRef.instance.loading || !this.componentRef.instance.currentStepIsValid()) {
      return null;
    }
    this.componentRef.instance.onClose(MODAL_NEXT_RESULT);
  }

  public finish(): void {
    if (this.componentRef.instance.loading || !this.componentRef.instance.currentStepIsValid()) {
      return null;
    }
    this.componentRef.instance.onClose(MODAL_SUCCESS_RESULT);
  }

  public print(): void {
    if (this.componentRef.instance.loading) {
      return null;
    }
    this.componentRef.instance.onClose(MODAL_PRINT_RESULT);
  }

  public destroyModalComponent(): void {
    this.componentRef.destroy();
  }
}
