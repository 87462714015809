import { Component, Input, OnChanges } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { Alert } from '../../models/alert';

@Component({
  selector: 'my-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnChanges {
  @Input() alert: Alert;
  private timer: any;

  constructor(private alertService: AlertService) {}

  public ngOnChanges(): void {
    if (this.alert.duration) {
      this.timer = setTimeout(() => {
        this.close(this.alert.index);
      }, this.alert.duration);
    }
  }

  public close(index: number): void {
    clearTimeout(this.timer);
    this.alertService.remove(index);
  }
}
