import { HttpParams } from '@angular/common/http';
import { Result, ResultFilter } from '../models/result';

export function ConfigureHttpParams(result: Result): HttpParams {
  let params: HttpParams = new HttpParams();
  if (!result) {
    return params;
  }
  if (result.search) {
    params = params.append('search', result.search);
  }
  if (result.page) {
    params = params.append('page', result.page.toString());
  }
  if (result.sort) {
    params = params.append('sort', result.sort.field).append('direction', result.sort.direction);
  }

  if (result.fixedFilters && result.fixedFilters.length > 0) {
    result.fixedFilters.forEach((filter: ResultFilter) => {
      params = params.append(filter.name + (filter.multiple ? '[]' : ''), filter.value);
    });
  }

  if (result.activeFilters && result.activeFilters.length > 0) {
    result.activeFilters.forEach((filter: ResultFilter) => {
      params = params.append(filter.name + (filter.multiple ? '[]' : ''), filter.value);
    });
  }

  if (result.activeGroups) {
    params = params.append(result.activeGroups.name, result.activeGroups.value);
  }

  return params;
}
