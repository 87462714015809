import { Warehouse } from '../models/warehouse';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse } from '../models/response';
import { ConfigureHttpParams } from '../lib/functions';
import { Result } from '../models/result';

@Injectable({
  providedIn: 'root'
})
export class WarehousesService {
  private subject: BehaviorSubject<Warehouse> = new BehaviorSubject(this.extractCurrentWarehouse());
  constructor(private http: HttpClient) {}

  public index(result?: Result): Observable<ApiResponse> {
    const params = ConfigureHttpParams(result);
    return this.http.get<ApiResponse>('api/admin/warehouses', { params });
  }

  public add(warehouse: Warehouse): Observable<ApiResponse> {
    return this.http.post<ApiResponse>('api/admin/warehouses/add', warehouse);
  }

  public modify(warehouseId: string, warehouse: Warehouse): Observable<ApiResponse> {
    return this.http.post<ApiResponse>('api/admin/warehouses/edit/' + warehouseId, warehouse);
  }

  public remove(warehouseId: string): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>('api/admin/warehouses/remove/' + warehouseId);
  }

  public observeWarehouse(): Observable<Warehouse> {
    return this.subject.asObservable();
  }

  public setWarehouse(warehouse: Warehouse): void {
    this.subject.next(warehouse);
  }

  public getActiveWarehouse(): Warehouse {
    return this.subject.getValue();
  }

  public extractCurrentWarehouse(): Warehouse {
    const storedWarehouse = localStorage.getItem('currentWarehouse');
    if (!storedWarehouse || storedWarehouse === 'null' || storedWarehouse === 'undefined') {
      return null;
    }
    const warehouse: Warehouse = JSON.parse(storedWarehouse);
    return warehouse;
  }

  public storeCurrentWarehouse(warehouse: Warehouse): void {
    localStorage.setItem('currentWarehouse', JSON.stringify(warehouse));
    this.subject.next(warehouse);
  }

  public removeCurrentWarehouse(): void {
    localStorage.removeItem('currentWarehouse');
    this.subject.next(null);
  }
}
