import { SearchModule } from 'src/app/shared/search/search.module';
import { MomentModule } from 'ngx-moment';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { LoadingModule } from 'src/app/shared/loading/loading.module';
import { DropdownModule } from 'src/app/shared/dropdown/dropdown.module';
import { CPanelComponent } from './components/cpanel/cpanel.component';
import { AccountGuard } from 'src/app/core/guards/account.guard';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MainComponent } from './components/main/main.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { MainRoutingModule } from './main-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from 'src/app/core/interceptors/error.interceptor';
import { SessionInterceptor } from 'src/app/core/interceptors/session.interceptor';
import localeEsMX from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'src/app/shared/modal/modal.module';
import { AlertModule } from 'src/app/shared/alert/alert.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Error404Component } from './components/error404/error404.component';
import * as moment from 'moment';
import { DashboardComponent } from './components/dashboard/dashboard.component';
moment.locale('es');
registerLocaleData(localeEsMX);

@NgModule({
  declarations: [
    MainComponent,
    CPanelComponent,
    Error404Component,
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MainRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    ModalModule,
    AlertModule,
    DropdownModule,
    LoadingModule,
    PipesModule,
    MomentModule,
    SearchModule,
    NgxChartsModule
  ],
  providers: [
    AuthGuard,
    AccountGuard,
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-MX' }
  ],
  bootstrap: [MainComponent]
})
export class MainModule {}
