import { MODAL_SUCCESS_RESULT } from './../modal/modal.component';
import { ModalRef } from './../../services/modal.service';
import { ModalInterface } from './../../interfaces/modal.interface';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'my-modal-confirm',
  templateUrl: './modal-confirm.component.html'
})
export class ModalConfirmComponent implements OnInit, ModalInterface {
  public modalRef: ModalRef;
  public message: string;
  constructor() { }

  public ngOnInit(): void {
    this.message = this.modalRef.getData('message');
  }

  public onClose(result: string): void {
    this.modalRef.close(result === MODAL_SUCCESS_RESULT);
  }
}
