import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { NgModule } from '@angular/core';
import { ModalComponent } from './components/modal/modal.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ModalComponent, ModalConfirmComponent
  ],
  exports: [
    ModalComponent, ModalConfirmComponent
  ]
})
export class ModalModule {}
