import { Component, Input, HostBinding, ElementRef, HostListener, OnChanges, Output } from '@angular/core';

@Component({
  selector: '[my-dropdown]',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnChanges {
  @Input() defaultIconText: string;
  @Input() defaultText: string;
  @Input() defaultImage: string;
  @Input() isSubmenu: boolean;
  @Input() direction: string;

  @HostBinding('class.active') active: boolean;
  @HostListener('click', ['$event.target']) onClick(targetElement: any) {
    this.active = !this.active;
  }
  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement: any) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.active = false;
    }
  }
  @HostListener('mouseover') toggleOn() {
    if (this.isSubmenu) {
      this.active = true;
    }
  }
  @HostListener('mouseout') toggleOff() {
    if (this.isSubmenu) {
      this.active = false;
    }
  }
  constructor(private _elementRef: ElementRef) {}

  public ngOnChanges(): void {
    if (this.selectItem) {
      this.active = false;
    }
  }

  public toggle(): void {
    this.active = !this.active;
  }

  public submenuToggle(result: boolean): void {
    if (result && this.isSubmenu) {
      this.active = true;
    } else if (!result && this.isSubmenu) {
      this.active = false;
    }
  }

  public selectItem(): void {
    this.active = false;
  }
}
