import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { Session } from '../models/session';
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private accountService: AccountService, private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const session: Session = this.accountService.getActiveSession();
    if ( session && !session.inactive) {
      return true;
    }

    this.router.navigate(['/account', 'login'], { queryParams: { return: state.url } });
    return false;
  }
}
