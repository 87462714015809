import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel, NavigationError, NavigationStart } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class SectorsService {
  private urlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private urlArraySubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  private sectorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private site: string;
  constructor(private router: Router, private loadingService: LoadingService) {
    this.router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loadingService.startLoadingState();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          const q = event.url.indexOf('?');
          const split = event.url.substr(0, q === -1 ? event.url.length : q).split('/');
          this.urlSubject.next('/' + (1 in split ? split[1] : '') + (2 in split ? '/' + split[2] : ''));
          this.urlArraySubject.next(split);
          this.sectorSubject.next(1 in split ? split[1] : '');

          this.loadingService.endLoadingState();
          break;
        }
      }
    });
  }

  public storeSite(): void {
    localStorage.setItem('site', this.site);
  }

  public extractSite(): string {
    return localStorage.getItem('site');
  }

  public getUrlAttrs(): string[] {
    const q = this.router.url.indexOf('?');
    const url = this.router.url.substr(0, q === -1 ? this.router.url.length : q).split('/');
    return url.length > 3 ? url.slice(3) : [];
  }

  public getUrl(): string {
    return this.urlSubject.getValue();
  }

  public getUrlAsArray(): string[] {
    return this.urlArraySubject.getValue();
  }

  public getSector(): string {
    return this.sectorSubject.getValue();
  }

  public observeUrl(): Observable<string> {
    return this.urlSubject.asObservable();
  }

  public observeUrlAsArray(): Observable<string[]> {
    return this.urlArraySubject.asObservable();
  }

  public observeSector(): Observable<string> {
    return this.sectorSubject.asObservable();
  }
}
