import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'my-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit, OnChanges {
  @Input() public loading: boolean;
  @Input() public error: boolean;
  @Input() public customLoadingText: string;
  @Input() public customErrorTitle: string;
  @Input() public customErrorDescription: string;
  @Input() public reloadEnabled = true;
  @Output() public reload: EventEmitter<boolean> = new EventEmitter();
  public animating: boolean;
  constructor() { }

  public ngOnInit(): void {}

  public ngOnChanges(): void {
    if (!this.error && !this.loading) {
      this.animating = true;
      setTimeout(() => {
        this.animating = false;
      }, 200);
    }
  }

  public onReload(): void {
    if (this.loading) {
      return;
    }
    this.reload.emit(true);
  }
}
