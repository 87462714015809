import { Part } from './../../../core/models/part';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'part' })
export class PartPipe implements PipeTransform {
  transform(part: Part): string {
    if (!part) {
      return 'Producto no disponible';
    }

    return part.name;
  }
}
