<div [className]="'ui dimmer active transition visible animating ' + (animating ? 'out fade' : 'in fade')" *ngIf="loading || error || animating">
  <div class="ui indeterminate text loader" *ngIf="loading && !error">
    {{ customLoadingText  || 'Cargando...' }}
  </div>
  <div class="ui centered middle aligned stackable grid po-error-container" *ngIf="error">
    <div class="row">
      <div class="seven wide column">
        <div class="ui placeholder segment" [class.loading]="loading">
          <div class="ui centered header">
            <img class="ui small image transition animating jiggle" src="assets/img/alert.png">
            <div class="content">
              {{ customErrorTitle || 'Ups! Ocurrió un problema al recuperar los datos' }}
              <div class="sub header">
                {{ customErrorDescription || 'No se pudieron recuperar los datos del servidor, intentelo nuevamente en unos minutos.' }}
              </div>
            </div>
          </div>
          <button class="ui primary labeled icon button" *ngIf="reloadEnabled" (click)="onReload()">
            <i class="icon redo"></i>
            Volver a intentarlo
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
