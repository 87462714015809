<div class="ui dimmer modals active visible po-background">
  <div class="ui active modal">
    <div class="header po-title">
      <img src="assets/img/logo.png" class="logo">
    </div>
    <div class="content po-center-aligned">
      <div class="ui icon massive header">
        <i class="icon frown"></i>
        <div class="content">
          404
          <div class="sub header">
            Página no encontrada
          </div>
        </div>
      </div>
      <a class="ui labeled icon button" [routerLink]="['/']">
        <i class="icon arrow left"></i>
        Volver
      </a>
    </div>
  </div>
</div>
