<form class="ui large form" [formGroup]="formGroup" [class.loading]="loading" (ngSubmit)="onClose('success')">
  <img class="ui small circular image centered" [src]="'assets/img/clients/' + (session.image ? session.image : 'blank.png')">
  <div class="field">
    <p>Hola <strong>{{ session.name }}</strong>, Ingrese su contraseña para continuar</p>
  </div>
  <div class="field">
    <label>Contraseña</label>
    <div class="ui left icon input">
      <i class="lock icon"></i>
      <input type="password" placeholder="Contraseña" formControlName="password">
    </div>
  </div>
</form>
