import { UsersService } from 'src/app/core/services/users.service';
import { WarehousesService } from 'src/app/core/services/warehouses.service';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/core/services/loading.service';
import { Subscription } from 'rxjs';
import { SectorsService } from 'src/app/core/services/sectors.service';
import { Session } from 'src/app/core/models/session';
import { AccountService } from 'src/app/core/services/account.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiResponse } from 'src/app/core/models/response';
import { Warehouse } from 'src/app/core/models/warehouse';
import { Branch } from 'src/app/core/models/branch';
import { BranchsService } from 'src/app/core/services/branchs.service';

@Component({
  selector: 'app-cpanel',
  templateUrl: './cpanel.component.html',
  styleUrls: ['./cpanel.component.css']
})
export class CPanelComponent implements OnInit, OnDestroy {
  public session: Session;
  public smallMenu: boolean;
  public mobileMenu: boolean;
  private sectorsSubscription: Subscription;
  private loadingSubscription: Subscription;
  public sector: string;
  public loading: boolean;
  public error: boolean;
  public urlLoading: boolean;
  public changingWarehouse: boolean;
  public changinBranch: boolean;
  public warehouses: Warehouse[];
  public branchs: Branch[];
  public branch: Branch;
  public warehouse: Warehouse;
  public loadingWarehouses: boolean;
  public errorWarehouses: boolean;
  public loadingBranchs: boolean;
  public errorBranchs: boolean;
  constructor(
    private accountService: AccountService,
    private sectorsService: SectorsService,
    private loadingService: LoadingService,
    private warehousesService: WarehousesService,
    private branchsService: BranchsService,
    private usersService: UsersService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.session = this.accountService.getActiveSession();
    this.warehouse = this.warehousesService.getActiveWarehouse();
    this.branch = this.branchsService.getActiveBranch();
    this.sectorsSubscription = this.sectorsService.observeUrlAsArray().subscribe((url: string[]) => {
      this.sector = url[1];
      if (this.mobileMenu) {
        this.mobileMenu = false;
        setTimeout(() => {
          this.mobileMenu = null;
        }, 150);
      }
    });
    this.loadingSubscription = this.loadingService.observeLoading().subscribe((loading: boolean) => {
      this.urlLoading = loading;
    });
    this.getData();
  }

  public getData(): void {
    if (this.loadingWarehouses || this.loadingBranchs) {
      return;
    }
    this.loadingWarehouses = true;
    this.warehousesService.index().subscribe((response: ApiResponse) => {
      this.loadingWarehouses = false;
      this.errorWarehouses = false;
      this.warehouses = response.data.results;
      if (!this.warehouse) {
        this.warehouse = this.warehouses[0];
        this.warehousesService.storeCurrentWarehouse(this.warehouse);
      }
      this.loadingBranchs = true;
      this.branchsService.index().subscribe((r: ApiResponse) => {
        this.loadingBranchs = false;
        this.errorBranchs = false;
        this.branchs = r.data.results;
        if (!this.branch) {
          this.branch = this.branchs[0];
        }
      }, () => {
        this.errorBranchs = true;
        this.loadingBranchs = false;
      });
    }, () => {
      this.loadingWarehouses = false;
      this.errorWarehouses = true;
    });

  }

  public ngOnDestroy(): void {
    this.sectorsSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
  }

  public toggleMenu(): void {
    this.smallMenu = !this.smallMenu;
    if (!this.smallMenu) {
      setTimeout(() => {
        this.smallMenu = null;
      }, 150);
    }
  }

  public toggleMobileMenu(): void {
    this.mobileMenu = !this.mobileMenu;
    if (!this.mobileMenu) {
      setTimeout(() => {
        this.mobileMenu = null;
      }, 150);
    }
  }

  public changeWarehouse(warehouse: Warehouse): void {
    if (this.changingWarehouse || warehouse.id === this.warehouse.id) {
      return;
    }
    this.changingWarehouse = true;
    this.usersService.changeWarehouse(warehouse.id).subscribe((response: ApiResponse) => {
      this.changingWarehouse = false;
      this.warehousesService.storeCurrentWarehouse(warehouse);
      this.warehouse = warehouse;
    }, () => {
      this.changingWarehouse = false;
    });
  }

  public changeBranch(branch: Branch): void {
    if (this.changinBranch || branch.id === this.branch.id) {
      return;
    }
    this.changinBranch = true;
    this.usersService.changeBranch(branch.id).subscribe((response: ApiResponse) => {
      this.changinBranch = false;
      this.branchsService.storeCurrentBranch(branch);
      this.warehouse = null;
      this.warehousesService.removeCurrentWarehouse();
      this.branch = branch;
      this.getData();
    }, () => {
      this.changinBranch = false;
    });
  }

  public logout(): void {
    this.accountService.logout(() => {
      this.warehousesService.removeCurrentWarehouse();
      this.branchsService.removeCurrentBranch();
      this.router.navigate(['/account', 'login']);
    });
  }

  public isSectorAvailable(sector: string): boolean {
    return sector == 'settings' && this.session.accessLevel < 9
      ? false
      : (!this.session.permission || this.session.permission.length === 0
        || this.session.permission.indexOf(sector) !== -1)
    ;
  }
}
