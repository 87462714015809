import { PartPipe } from './pipes/part.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { HtmlSafePipe } from './pipes/html.safe.pipe';
import { NgModule } from '@angular/core';
import { HighlightPipe } from './pipes/highlight.pipe';
import { UserPipe } from './pipes/user.pipe';

@NgModule({
  declarations: [
    HighlightPipe,
    UserPipe,
    TruncatePipe,
    HtmlSafePipe,
    PartPipe
  ],
  exports: [
    HighlightPipe,
    UserPipe,
    TruncatePipe,
    HtmlSafePipe,
    PartPipe
  ]
})
export class PipesModule {}
