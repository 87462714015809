import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(value: string, args: string): string {
    if (args && value) {
      value = String(value);
      args = args.trim();
      const startIndex = value.toLowerCase().indexOf(args.toLowerCase());
      if (startIndex !== -1) {
        const matchingString = value.substr(startIndex, args.length);
        return value.replace(matchingString, '<mark>' + matchingString + '</mark>');
      }

    }
    return value;
  }
}
