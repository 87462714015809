import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Alert } from '../models/alert';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private alerts: Alert[] = [];
  private subject: Subject<Alert[]> = new Subject();

  public add(message: string, alert?: Alert): number {
    this.removeFixed();
    if (alert) {
      alert.index = this.alerts.length;
      alert.text = message;
    } else {
      alert = {
        index: this.alerts.length,
        text: message,
        duration: 5000,
        type: 'default'
      };
    }
    this.alerts.push(alert);
    this.subject.next(this.alerts);
    return this.alerts.length - 1;
  }

  public remove(index: number): void {
    const newAlerts: Alert[] = [];
    for (const alert of this.alerts) {
      if (alert.index !== index) {
        newAlerts.push(alert);
      } else if (alert.onClose) {
        alert.onClose();
      }
    }
    this.alerts = newAlerts;
    this.subject.next(newAlerts);
  }

  public observeAlerts(): Observable<Alert[]> {
    return this.subject.asObservable();
  }

  public removeFixed() {
    for (const alert of this.alerts) {
      if ( !alert.duration ) {
        this.remove(alert.index);
      }
    }
  }
}
