import { Error404Component } from './components/error404/error404.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { CPanelComponent } from './components/cpanel/cpanel.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';

const routes: Routes = [
  { path: '', component: CPanelComponent, canActivate: [AuthGuard], children: [
      { path: '', component: DashboardComponent },
      { path: 'parts', loadChildren: () => import('../parts/parts.module').then(m => m.PartsModule) },
      { path: 'reports', loadChildren: () => import('../reports/reports.module').then(m => m.ReportsModule) },
      { path: 'settings', loadChildren: () => import('../settings/settings.module').then(m => m.SettingsModule) }
    ]
  },
  { path: 'account', loadChildren: () => import('../account/account.module').then(m => m.AccountModule) },
  { path: '404', component: Error404Component },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule {}
