import { DashboardService } from 'src/app/core/services/dashboard.service';
import { ApiResponse } from 'src/app/core/models/response';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { WarehousesService } from 'src/app/core/services/warehouses.service';
import { Subscription } from 'rxjs';
import { Warehouse } from 'src/app/core/models/warehouse';
import { AccountService } from 'src/app/core/services/account.service';
import { Session } from 'src/app/core/models/session';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public dashboard: any;
  public error: boolean;
  public loading: boolean;
  public session: Session;
  private warehouseSubscription: Subscription;

  constructor(
    private dashboardService: DashboardService,
    private warehouseService: WarehousesService,
    private accountService: AccountService
  ) { }

  public ngOnInit(): void {
    this.session = this.accountService.getActiveSession();
    this.warehouseSubscription = this.warehouseService.observeWarehouse().subscribe((warehouse: Warehouse) => {
      if (warehouse) {
        this.getData();
      }
    });
  }

  public ngOnDestroy(): void {
    this.warehouseSubscription.unsubscribe();
  }

  public getData(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.dashboardService.summary().subscribe((response: ApiResponse) => {
      this.loading = false;
      this.error = false;
      this.dashboard = response.data.dashboard;
    }, () => {
      this.loading = false;
      this.error = true;
    });
  }
}
