import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private subject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public observeLoading(): Observable<boolean> {
    return this.subject.asObservable();
  }

  public startLoadingState(): void {
    this.subject.next(true);
  }

  public endLoadingState(): void {
    this.subject.next(false);
  }
}
