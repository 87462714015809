import { User } from '../models/user';
import { Observable } from 'rxjs';
import { Result } from '../models/result';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../models/response';
import { ConfigureHttpParams } from '../lib/functions';
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) {}

  public index(result: Result): Observable<ApiResponse> {
    const params = ConfigureHttpParams(result);
    return this.http.get<ApiResponse>('api/admin/users', { params });
  }

  public add(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>('api/admin/users/add', user);
  }

  public modify(userId: string, user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>('api/admin/users/edit/' + userId, user);
  }

  public remove(userId: string): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>('api/admin/users/remove/' + userId);
  }

  public view(userId: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>('api/admin/users/view/' + userId);
  }

  public changeBranch(branchId: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>('api/admin/users/branch/' + branchId);
  }

  public changeWarehouse(warehouseId: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>('api/admin/users/warehouse/' + warehouseId);
  }
}
