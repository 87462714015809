import { Component, OnInit, OnDestroy } from '@angular/core';
import { Alert } from 'src/app/shared/alert/models/alert';
import { ModalRef, ModalService } from 'src/app/shared/modal/services/modal.service';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/alert/services/alert.service';
@Component({
  selector: 'app-root',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnDestroy {
  public alerts: Alert[] = [];
  public modals: ModalRef[] = [];
  private alertSubscription: Subscription;
  private modalSubscription: Subscription;

  constructor(private alertService: AlertService, private modalService: ModalService) {}

  public ngOnInit(): void {
    this.alertSubscription = this.alertService.observeAlerts().subscribe((alerts: Alert[]) => {
      this.alerts = alerts;
    });
    this.modalSubscription = this.modalService.observeActiveModals().subscribe((modals: ModalRef[]) => {
      this.modals = modals;
    });
  }

  public ngOnDestroy(): void {
    this.alertSubscription.unsubscribe();
    this.modalSubscription.unsubscribe();
  }
}
