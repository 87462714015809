import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, startLimit: number = 20, middle: boolean = false): string {
    if (value.length <= startLimit) {
      return value;
    }
    const separator = '<span class="cnc-truncate-text" title="' + value + '">[..]</span>';
    if (middle) {
      const frontChars = Math.ceil(startLimit / 2);
      const backChars = Math.floor(startLimit / 2);
      return value.substr(0, frontChars) + ' ' + separator + ' ' + value.substr(value.length - backChars);
    }

    const limit = value.substring(0, startLimit).lastIndexOf(' ');

    return value.substring(0, limit) + ' ' + separator;
  }
}
