<span class="text">
  <i [className]="defaultIconText + ' ' + (!defaultText ? 'only' : '')" *ngIf="defaultIconText"></i>
  <img [src]="defaultImage" *ngIf="defaultImage" class="ui avatar image">
  <span [class.po-xs-hidden]="defaultImage">{{ defaultText }}</span>
</span>
<i [className]="'icon dropdown ' + (direction || '')"></i>
<div [className]="'menu transition ' + (direction || '')
+ (active === true ? ' active visible animating slide down in' : (active === false ? ' animating slide down out' : '  hidden'))">
  <ng-content></ng-content>
</div>
