import { Component, OnInit } from '@angular/core';
import { ModalInterface } from 'src/app/shared/modal/interfaces/modal.interface';
import { ModalRef } from 'src/app/shared/modal/services/modal.service';
import { AccountService, Credentials } from 'src/app/core/services/account.service';
import { MODAL_CANCEL_RESULT } from 'src/app/shared/modal/components/modal/modal.component';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Session } from 'src/app/core/models/session';
import { ApiResponse } from 'src/app/core/models/response';

@Component({
  selector: 'app-lock',
  templateUrl: './lock.component.html'
})
export class LockComponent implements OnInit, ModalInterface {
  public modalRef: ModalRef;
  public formGroup: FormGroup;
  public session: Session;
  public loading: boolean;
  constructor(private accountService: AccountService) { }

  public ngOnInit(): void {
    this.session = this.modalRef.getData('session');
    this.formGroup = new FormGroup({
      email: new FormControl(this.session.email, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required])
    });
  }

  public onClose(result: string): void {
    if (result === MODAL_CANCEL_RESULT) {
      this.accountService.removeCurrentSession();
      this.modalRef.close(false);
      return;
    }
    if ( this.loading || this.formGroup.invalid) {
      return null;
    }

    this.loading = true;
    const credentials: Credentials = {
      password: this.formGroup.get('password').value,
      email: this.formGroup.get('email').value
    };

    this.accountService.login(credentials).subscribe((response: ApiResponse) => {
      this.loading = false;
      if (response.status === 'success') {
        this.accountService.storeCurrentSession(response.data.session);
        this.modalRef.close(true);
      }
    }, () => {
      this.loading = false;
    });
  }
}
