import { ModalComponent } from './../components/modal/modal.component';
import { ModalConfirmComponent } from './../components/modal-confirm/modal-confirm.component';
import { Injectable, Type } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ModalInterface } from '../interfaces/modal.interface';

export class ModalCustomButton {
  text: string;
  alternatedText?: string;
  callback: (instance: ModalInterface) => void;
  type?: 'primary' | 'default' | 'secondary' | 'teal' | 'red' | 'yellow' | string;
  icon?: string;
  float?: 'right' | 'left' | string;
}

export class ModalOptions {
  title?: string;
  basic?: boolean;
  data?: any;
  onClose?: (result: any) => void;
  icon?: string;
  defaultButtons?: (
    'cancel' | 'send' | 'success' | 'preview' | 'print' | 'stepper' |
    'done' | 'confirm' | 'previous' | 'next' | 'redo' | 'finish' | 'cart'
    )[];
  customButtons?: ModalCustomButton[];
  size?: 'tiny' | 'small' | 'mini' | 'large' | 'longer' | 'fullscreen';
  scrolling?: boolean;
  modalScrolling?: boolean;
  unpadded?: boolean;
  closable?: boolean;
  image?: string;
  imageSize?: 'small' | 'tiny' | 'big' | 'medium';
  maximizable?: boolean;
  minHeight?: number;
}

export class ModalRef {
  public options: ModalOptions;
  public component: Type<{}>;
  public result: any;
  public onCloseSubject: Subject<any> = new Subject<any>();
  public onActionSubject: Subject<any> = new Subject<string>();
  public modal?: ModalComponent;

  constructor(data: any) {
    this.options = data.options;
    this.component = data.component;
    this.modal = data.modal;
  }

  public onClose(): Observable<any> {
    return this.onCloseSubject.asObservable();
  }

  public onAction(): Observable<string> {
    return this.onActionSubject.asObservable();
  }

  public close(result: any): void {
    this.modal.close(() => {
      this.onCloseSubject.next(result);
    });
  }

  public action(name: string): void {
    this.onActionSubject.next(name);
  }

  public getData(name?: string): any {
    return this.options
      ? (name && this.options.data && name in this.options.data ? this.options.data[name] : (!name ? this.options.data : null))
      : null
    ;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: ModalRef[] = [];
  private modalsSubject: Subject<ModalRef[]> = new Subject();

  public confirm(message: string): ModalRef {
    return this.open(ModalConfirmComponent, {
      title: 'MODAL.CONFIRM-TITLE',
      defaultButtons: ['cancel', 'success'],
      closable: true,
      basic: true,
      data: {
        message
      }
    });
  }

  public open(component?: Type<{}>, options?: ModalOptions): ModalRef {
    const modalRef: ModalRef = new ModalRef({
      options: options ? options : {
        title: 'MODAL.ADVERTISE-TITLE',
        defaultButtons: ['cancel', 'success'],
        closable: true
      },
      component
    });

    if (!('defaultButtons' in modalRef.options)) {
      modalRef.options.defaultButtons = ['cancel', 'success'];
    }
    if ( !('closable' in modalRef.options) ) {
      modalRef.options.closable = true;
    }
    this.modals.push(modalRef);
    this.modalsSubject.next(this.modals);

    const subscription = modalRef.onClose().subscribe(() => {
      this.modals.pop();
      subscription.unsubscribe();
      this.modalsSubject.next(this.modals);
    });

    return modalRef;
  }

  public observeActiveModals(): Observable<ModalRef[]> {
    return this.modalsSubject.asObservable();
  }
}
