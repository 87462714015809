import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'my-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  public searchFormGroup: FormGroup;
  public searchField: boolean;
  public loading: boolean;
  private searchTimer: any;
  @Input() styled: boolean;
  @Input() disabled: boolean;
  @Input() searchWords: string;
  @Output() search = new EventEmitter();
  @Input() placeholder: string;
  constructor() {}

  public ngOnInit(): void {
    this.searchFormGroup = new FormGroup({
      search: new FormControl()
    });
  }

  public onSearch(): void {
    const searchWords: string = this.searchFormGroup.get('search').value;
    if (searchWords === this.searchWords || this.disabled) {
      return;
    }
    this.loading = true;
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = setTimeout(() => {
      this.loading = false;
      this.searchWords = searchWords;
      this.executeSearch(this.searchWords);
    }, 500);
  }

  public removeSearch(): void {
    this.searchWords = null;
    this.searchFormGroup.get('search').reset();
    this.searchField = false;
    this.executeSearch(null);
  }

  public showSearchField(): void {
    if (this.searchField) {
      this.searchField = false;
    } else {
      this.searchField = true;
    }
  }

  public executeSearch(text: string): void {
    this.search.emit(text);
  }
}
